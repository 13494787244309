import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149')
];

export const server_loads = [2,5,6,7,11,16,20];

export const dictionary = {
		"/(app)": [~23,[2],[3]],
		"/(app)/comptabilite/analytiques": [24,[2,4],[3]],
		"/(app)/comptabilite/analytiques/[id=crudid]": [25,[2,4],[3]],
		"/(app)/comptabilite/auxiliaires": [26,[2,4],[3]],
		"/(app)/comptabilite/auxiliaires/natures": [28,[2,4],[3]],
		"/(app)/comptabilite/auxiliaires/natures/[id=crudid]": [29,[2,4],[3]],
		"/(app)/comptabilite/auxiliaires/[id=crudid]": [27,[2,4],[3]],
		"/(app)/comptabilite/cerfas": [30,[2,4],[3]],
		"/(app)/comptabilite/cerfas/new": [31,[2,4],[3]],
		"/(app)/comptabilite/cerfas/signatures": [32,[2,4],[3]],
		"/(app)/comptabilite/cerfas/signatures/[id=crudid]": [33,[2,4],[3]],
		"/(app)/comptabilite/comptes-comptables": [34,[2,4],[3]],
		"/(app)/comptabilite/comptes-comptables/natures": [36,[2,4],[3]],
		"/(app)/comptabilite/comptes-comptables/[id=crudid]": [35,[2,4],[3]],
		"/(app)/comptabilite/exports": [~37,[2,4],[3]],
		"/(app)/comptabilite/extraits-bancaires": [38,[2,4,5],[3]],
		"/(app)/comptabilite/extraits-bancaires/[id=crudid]": [39,[2,4,5],[3]],
		"/(app)/comptabilite/factures-fournisseurs": [40,[2,4,6],[3]],
		"/(app)/comptabilite/factures-fournisseurs/import": [42,[2,4,6],[3]],
		"/(app)/comptabilite/factures-fournisseurs/notes-de-frais": [43,[2,4,6],[3]],
		"/(app)/comptabilite/factures-fournisseurs/notes-de-frais/import": [45,[2,4,6],[3]],
		"/(app)/comptabilite/factures-fournisseurs/notes-de-frais/[id=crudidonly]": [44,[2,4,6],[3]],
		"/(app)/comptabilite/factures-fournisseurs/[id=crudidonly]": [41,[2,4,6],[3]],
		"/(app)/comptabilite/imports": [~46,[2,4],[3]],
		"/(app)/comptabilite/journaux": [47,[2,4],[3]],
		"/(app)/comptabilite/journaux/natures": [49,[2,4],[3]],
		"/(app)/comptabilite/journaux/[id=crudid]": [48,[2,4],[3]],
		"/(app)/comptabilite/remises-cb": [50,[2,4],[3]],
		"/(app)/comptabilite/remises-cheques": [51,[2,4,7],[3]],
		"/(app)/comptabilite/remises-cheques/[id=crudid]": [52,[2,4,7],[3]],
		"/(app)/comptabilite/remises-cheques/[id=crudid]/view": [53,[2,4,7],[3]],
		"/(app)/comptabilite/remises-especes": [54,[2,4],[3]],
		"/(app)/comptabilite/rubriques": [55,[2,4],[3]],
		"/(app)/comptabilite/rubriques/[id=crudid]": [56,[2,4],[3]],
		"/(app)/cotisations": [~57,[2],[3]],
		"/(app)/distributions": [58,[2,8],[3]],
		"/(app)/distributions/acquereurs": [60,[2,8],[3]],
		"/(app)/distributions/acquereurs/[id=crudid]": [61,[2,8],[3]],
		"/(app)/distributions/stats": [~62,[2,8],[3]],
		"/(app)/distributions/stock": [63,[2,8],[3]],
		"/(app)/distributions/stock/entree": [64,[2,8],[3]],
		"/(app)/distributions/[id=crudid]": [59,[2,8],[3]],
		"/(app)/dons": [~65,[2],[3]],
		"/(app)/erouv": [66,[2,9],[3]],
		"/(app)/erouv/sites": [67,[2,9,10],[3]],
		"/(app)/erouv/sites/[id=crudid]": [68,[2,9,10],[3]],
		"/(app)/erouv/tournees": [69,[2,9],[3]],
		"/(app)/erouv/tournees/[id=crudid]": [70,[2,9],[3]],
		"/(app)/erouv/tournees/[id=crudid]/rapports": [71,[2,9],[3]],
		"/(app)/factures": [72,[2],[3]],
		"/(app)/factures/[id=crudid]": [73,[2],[3]],
		"/(app)/infos": [74,[2],[3]],
		"/(login)/login": [147,[21],[22]],
		"/(login)/login/ask-reset-password": [148,[21],[22]],
		"/(login)/login/reset-password": [149,[21],[22]],
		"/(app)/membres": [75,[2,11],[3]],
		"/(app)/membres/[id=crudid]": [76,[2,11,12],[3]],
		"/(app)/membres/[id=crudid]/cerfas": [77,[2,11,12],[3]],
		"/(app)/membres/[id=crudid]/evenements": [~78,[2,11,12,13],[3]],
		"/(app)/membres/[id=crudid]/foyers": [79,[2,11,12],[3]],
		"/(app)/membres/[id=crudid]/historique": [80,[2,11,12],[3]],
		"/(app)/membres/[id=crudid]/messages": [81,[2,11,12],[3]],
		"/(app)/membres/[id=crudid]/pieces-jointes": [~82,[2,11,12],[3]],
		"/(app)/membres/[id=crudid]/references": [~83,[2,11,12,14],[3]],
		"/(app)/membres/[id=crudid]/services": [84,[2,11,12],[3]],
		"/(app)/parametres/actions": [85,[2,15],[3]],
		"/(app)/parametres/actions/[id=crudid]": [86,[2,15],[3]],
		"/(app)/parametres/articles-distribution": [87,[2,15],[3]],
		"/(app)/parametres/articles-distribution/categories": [89,[2,15],[3]],
		"/(app)/parametres/articles-distribution/categories/[id=crudid]": [90,[2,15],[3]],
		"/(app)/parametres/articles-distribution/[id=crudid]": [88,[2,15],[3]],
		"/(app)/parametres/banques": [91,[2,15],[3]],
		"/(app)/parametres/banques/[id=crudid]": [92,[2,15],[3]],
		"/(app)/parametres/campagnes-distribution": [93,[2,15],[3]],
		"/(app)/parametres/campagnes-distribution/[id=crudid]": [94,[2,15],[3]],
		"/(app)/parametres/cimetieres": [95,[2,15],[3]],
		"/(app)/parametres/civilites": [96,[2,15],[3]],
		"/(app)/parametres/cohortes-distribution": [97,[2,15],[3]],
		"/(app)/parametres/cohortes-distribution/[id=crudid]": [98,[2,15],[3]],
		"/(app)/parametres/communautes": [99,[2,15],[3]],
		"/(app)/parametres/communautes/[id=crudid]": [~100,[2,15],[3]],
		"/(app)/parametres/derniers-numeros": [101,[2,15],[3]],
		"/(app)/parametres/derniers-numeros/[id=crudidonly]": [102,[2,15],[3]],
		"/(app)/parametres/ecoles": [103,[2,15],[3]],
		"/(app)/parametres/emplacements-stockage": [104,[2,15],[3]],
		"/(app)/parametres/emplacements-stockage/[id=crudid]": [105,[2,15],[3]],
		"/(app)/parametres/magazines": [106,[2,15],[3]],
		"/(app)/parametres/modes-reglement": [107,[2,15],[3]],
		"/(app)/parametres/modes-reglement/[id=crudid]": [108,[2,15],[3]],
		"/(app)/parametres/oratoires": [109,[2,15],[3]],
		"/(app)/parametres/oratoires/[id=crudid]": [110,[2,15],[3]],
		"/(app)/parametres/professions": [111,[2,15],[3]],
		"/(app)/parametres/professions/[id=crudid]": [112,[2,15],[3]],
		"/(app)/parametres/rites": [113,[2,15],[3]],
		"/(app)/parametres/salaries": [114,[2,15],[3]],
		"/(app)/parametres/salaries/[id=crudid]": [115,[2,15],[3]],
		"/(app)/parametres/structures-juridiques": [116,[2,15],[3]],
		"/(app)/parametres/types-demandes": [117,[2,15],[3]],
		"/(app)/parametres/types-demandes/[id=crudid]": [~118,[2,15],[3]],
		"/(app)/parametres/types-depenses": [119,[2,15],[3]],
		"/(app)/parametres/types-depenses/[id=crudid]": [120,[2,15],[3]],
		"/(app)/parametres/types-pj": [121,[2,15],[3]],
		"/(app)/parametres/types-services": [122,[2,15],[3]],
		"/(app)/parametres/types-services/[id=crudid]": [~123,[2,15],[3]],
		"/(app)/parametres/utilisateurs": [124,[2,15],[3]],
		"/(app)/parametres/utilisateurs/profils": [126,[2,15],[3]],
		"/(app)/parametres/utilisateurs/profils/[id=crudid]": [127,[2,15],[3]],
		"/(app)/parametres/utilisateurs/[id=crudid]": [125,[2,15],[3]],
		"/(app)/planning": [128,[2,16],[3]],
		"/(app)/planning/agents-securite": [129,[2,16,17],[3]],
		"/(app)/planning/agents-securite/[id=crudid]": [130,[2,16,17],[3]],
		"/(app)/planning/alertes": [131,[2,16,18],[3]],
		"/(app)/planning/alertes/[id=crudid]": [132,[2,16,18],[3]],
		"/(app)/planning/batiments": [133,[2,16,19],[3]],
		"/(app)/planning/batiments/[id=crudid]": [134,[2,16,19],[3]],
		"/(app)/planning/evenements/[id=crudidonly]": [135,[2,16],[3]],
		"/(app)/planning/recherche": [136,[2,16],[3]],
		"/(app)/planning/surveillances": [~137,[2,16],[3]],
		"/(app)/produits": [138,[2],[3]],
		"/(app)/produits/categories": [140,[2],[3]],
		"/(app)/produits/categories/[id=crudid]": [141,[2],[3]],
		"/(app)/produits/[id=crudid]": [139,[2],[3]],
		"/(app)/promesses-dons": [142,[2],[3]],
		"/(app)/services/demandes": [143,[2,20],[3]],
		"/(app)/services/demandes/[id=crudid]": [~144,[2,20],[3]],
		"/(app)/services/services": [145,[2,20],[3]],
		"/(app)/versions": [146,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';