import { dev } from '$app/environment';
import { getUser } from '$stores/user';
import * as Sentry from '@sentry/sveltekit';
import { get } from 'svelte/store';

Sentry.init({
	dsn: 'https://74c30eea83cb41d586a1285b7b08381b@o4504496526917632.ingest.sentry.io/4504496528162816',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	//integrations: [new Sentry.Replay()],
	replaysSessionSampleRate: dev ? 0 : 0.1,
	replaysOnErrorSampleRate: 1.0
});

const myErrorHandler = ({ event }) => {
	Sentry.setContext('url', event.url);
	Sentry.setContext('params', event.params);

	const userLogged = get(getUser());

	Sentry.setUser({
		ip_address: event.getClientAddress(),
		email: userLogged?.email,
		username: userLogged?.email,
		id: userLogged?.id?.toString()
	});
};

export const handleError = dev ? undefined : Sentry.handleErrorWithSentry(myErrorHandler);
